const idPortalApi = "77af21c5bfaeb849483edad4db147b0c8f5d01cc";

module.exports = {
  idPortalApi: `${idPortalApi}`,
  // Produção
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  //urlApi: `http://ec2-54-232-59-57.sa-east-1.compute.amazonaws.com:8004/${idPortalApi}`,
  // Local
  // urlApi: `http://localhost:8004/${idPortalApi}`,

  //config botões e imagens
  urlADM: `https://administracaopublica.com.br`,

  dnsPortal: "nossasenhoradenazare.pi.gov.br",
  NomePortal: "Nossa Senhora de Nazaré",
  ufPortal: "PI",
  NomeTipoPortal: "Prefeitura Municipal", //Prefeitura ou Câmara
  TipoPortal: 3, //3 = Prefeitura 4=Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: false, //Essa opção informa se a rota de visualização de uma única noticia está disponível (Raposa gera as noticias pelo lado do servidor com nextjs)

  countNewsHome: 12, //quantidade de noticias na home

  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario

  link: "http://www.transparenciadministrativa.com.br", //Onde o token abaixo ser[a utilizado]
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem", //Onde o token abaixo ser[a utilizado]
  tokenLink: `${idPortalApi}`, //Token para acessar o WWW.transparência

  urlEmDeploy: "https://nossasenhoradenazare.pi.gov.br", //usada para link de compartilhamento
  imagemURL: "https://cdn.administracaopublica.com.br/complementares/", //link para a imagem
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  corIcones: "#000080",

  link_youtube: `#`,
  link_tiktok: `#`,
  link_twitter: `#`,
  link_threads: `#`,
  link_localize: `https://maps.app.goo.gl/eAkMqmZ8DtRBSBWD7`,
  //Mapa da pagina Municipio/Mapa
  link_mapa: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d109965.53969780887!2d-42.22537952965484!3d-4.633916108685055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7922316cae3f3cf%3A0x14701295176007a4!2sNossa%20Senhora%20de%20Nazar%C3%A9%20-%20State%20of%20Piau%C3%AD!5e0!3m2!1sen!2sbr!4v1742491864142!5m2!1sen!2sbr`,
};
